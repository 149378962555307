'use strict';

import { elemSiblings, scrollToElem, slideUp, slideDown } from './support';

export default class Accordion {
    constructor() {
        this.toggle = document.querySelectorAll('.js-accordion_trigger');

        this.listeners();
    }

    listeners() {
        this.toggle.forEach(el => {
            if (!document.getElementById(el.dataset.block).classList.contains('open'))
                document.getElementById(el.dataset.block).querySelector('.js-accordion_container').style.display = 'none';

            el.addEventListener('click', this.toggleAccordion, false);
        });

        // Invalid Accordion Forms
        if(typeof(document.querySelector('.js-accordion_block')) != 'undefined' && document.querySelector('.js-accordion_block') != null) {
            document.querySelectorAll('.js-accordion_block').forEach(accordion => {
                let formsInvalid = accordion.querySelectorAll('.is-invalid');
                if (formsInvalid.length > 0) {
                    accordion.classList.add('open');
                    accordion.querySelector('.js-accordion_container').style.display = 'block';
                }
            });
        }
    }

    toggleAccordion(el) {
        let accBlock = document.getElementById(el.currentTarget.dataset.block);
        let accContainer = accBlock.querySelector('.js-accordion_container');

        if (!accContainer.classList.contains('is-animating')) {

            if (accBlock.classList.contains('open')) {
                accBlock.classList.remove('open');
                slideUp(accContainer);
            } else {
                accBlock.classList.add('open');
                slideDown(accContainer);

                if (!accContainer.dataset.noscroll) {
                    setTimeout(() => {
                        scrollToElem(accBlock);
                    }, 300);
                }
            }

            // Close siblings
            let elSiblings = elemSiblings(accBlock);
            elSiblings.forEach(sibling => {
                sibling.classList.remove('open')
                sibling.querySelector('.js-accordion_trigger').classList.remove('open');
                slideUp(sibling.querySelector('.js-accordion_container'));
            });

            // Check for open modals
            if (accBlock.closest('.js-modal_wrapper.open')) {
                setTimeout(() => {
                    let modal = accBlock.closest('.js-modal_wrapper.open');
                    let heightBody = window.innerHeight;
                    let heightModalContent = modal.querySelector('.js-modal_content').offsetHeight;

                    if (heightModalContent >= heightBody)
                        modal.classList.add('fixedHeight');
                    else
                        modal.classList.remove('fixedHeight');
                }, 300)
            }
        }
    }
}
