import { checkModalHeight } from './modal-window';

if (
    typeof document.querySelector('.coverage-calculator_modal') !=
        'undefined' &&
    document.querySelector('.coverage-calculator_modal') != null
) {
    // INIT
    updateCoverageCalculator();

    document.querySelectorAll('.coverage-calculator_wall').forEach((wall) => {
        calcSQM(wall);
    });

    // Add
    document.querySelector('.js_add-wall').addEventListener('click', () => {
        const lastWall = document.querySelector(
            '.coverage-calculator_wall:last-child'
        );
        const newWall = lastWall.cloneNode(true);

        // Listen inputs
        newWall.querySelector('.js_sqm').innerHTML = 0;
        calcSQM(newWall, true);

        // Listen remove wall button
        const btnRemove = newWall.querySelector('.js_remove-wall');
        btnRemove.addEventListener('click', removeWall);

        // Create new wall
        lastWall.after(newWall);

        // Update calculator
        updateCoverageCalculator();

        // Check modal height
        checkModalHeight(document.getElementById('modal-coverage-calculator'));
    });

    // Remove
    document.querySelectorAll('.js_remove-wall').forEach((btnRemove) => {
        btnRemove.addEventListener('click', removeWall);
    });

    // Exclude
    document
        .querySelector('.js_exclude-smq input')
        .addEventListener('change', (elem) => getExclude(elem));
    document
        .querySelector('.js_exclude-smq input')
        .addEventListener('keyup', (elem) => getExclude(elem));

    function getExclude(elem) {
        if (elem.target.value <= 0) elem.target.value = '';

        updateCoverageCalculator();
    }

    function removeWall(event) {
        // Remove row
        event.currentTarget.parentElement.parentElement.remove();

        // Update calculator
        updateCoverageCalculator();

        // Check modal height
        checkModalHeight(document.getElementById('modal-coverage-calculator'));
    }

    function calcSQM(wall, clear = false) {
        wall.querySelectorAll('input').forEach((input) => {
            if (clear) input.value = '';

            input.addEventListener('change', () => getValues(wall));
            input.addEventListener('keyup', () => getValues(wall));
        });
    }

    function getValues(wall) {
        let wallLength = wall.querySelector('input[name="length"]').value;
        let wallHeight = wall.querySelector('input[name="height"]').value;

        if (wallLength <= 0) {
            wallLength = '';
            wall.querySelector('input[name="length"]').value = '';
        }

        if (wallHeight <= 0) {
            wallHeight = '';
            wall.querySelector('input[name="height"]').value = '';
        }

        // Update wall SQM
        wall.querySelector('.js_sqm').innerHTML = (
            wallLength * wallHeight
        ).toFixed(2);

        // Update calculator
        updateCoverageCalculator();
    }

    function updateCoverageCalculator() {
        total = 0;

        document
            .querySelectorAll('.coverage-calculator_wall')
            .forEach((row, index) => {
                // Store total
                total += Number(row.querySelector('.js_sqm').innerHTML);

                // Set wall number
                row.querySelector('.wall_no').innerHTML = index + 1;
            });

        // Update total SQM
        document.querySelector('.js_sqm-total').innerHTML = (
            total - document.querySelector('.js_exclude-smq input').value
        ).toFixed(2);

        // Update total litres
        document.querySelector('.js_liters-total').innerHTML = (
            (total - document.querySelector('.js_exclude-smq input').value) /
            5.5
        ).toFixed(2);
    }

    // Update WC Form
    if (
        typeof document.querySelector('.cc-select-amount-button') !=
            'undefined' &&
        document.querySelector('.cc-select-amount-button') != null
    ) {
        document
            .querySelector('.cc-select-amount-button')
            .addEventListener('click', () => {
                let totalLitres =
                    document.querySelector('.js_liters-total').innerHTML;

                if (totalLitres != 0.0) {
                    let size = '1l';
                    let quantity = 1;

                    if (totalLitres > 5) {
                        size = '5l';
                        quantity = Math.ceil(totalLitres / 5).toFixed();
                    }
                    if (totalLitres <= 5) {
                        size = '5l';
                        quantity = 1;
                    }
                    if (totalLitres <= 2.5) {
                        size = '2-5l';
                        quantity = 1;
                    }
                    if (totalLitres <= 1) {
                        size = '1l';
                        quantity = 1;
                    }

                    document.getElementById('pa_select-size').value = size;
                    document.querySelector('.variations_form input.qty').value =
                        quantity;

                    jQuery('#pa_select-size').trigger('change');
                    jQuery('.variations_form input.qty').trigger('change');
                }
            });
    }
}
