import { elemSiblings, contrast } from './support';
import { toggleModalWindow, checkModalHeight } from './modal-window';

const metaApp = document.querySelector('meta[name="app"]') ? JSON.parse(document.querySelector('meta[name="app"]').content) : null;
const ck = metaApp ? metaApp.wc_ck : null;
const cs = metaApp ? metaApp.wc_cs : null;
const tagId = document.getElementById('colour-scheme-builder-accordion') ? document.getElementById('colour-scheme-builder-accordion').dataset.tagId : null;

// INIT Colors
let windowLocationSearch = window.location.search;
let colorA = colorB = colorC = null;
let rotation = 0;
let init = false;

// INIT Modal
if (windowLocationSearch) {
    let openModal = false;

    windowLocationSearch = window.location.search.slice(1).split('&').map(p => p.split('='));

    windowLocationSearch.forEach(elem => {
        if (elem[0] == 'a' || elem[0] == 'b' || elem[0] == 'c')
            openModal = true;
    });

    if (openModal) {
        toggleModalWindow('modal-colour-scheme-builder');
        initCSB();
    }
}

// Load products and create the sliders
window.addEventListener('click', (event) => {
    if (event.target.classList.contains('colour-scheme-builder_trigger'))
        initCSB();

    if (event.target.classList.contains('js-add-colour-scheme'))
        initCSB();
});

if (ck, cs, tagId) {

    // Filter colors by shade
    document.querySelectorAll('.colour-scheme-builder_accordion-item select[name="tags"]').forEach(selectShade => {
        selectShade.addEventListener('change', (event) => {
            let accordionItem = selectShade.closest('.colour-scheme-builder_accordion-item');
            let palette = accordionItem.dataset.palette;
            let slider = accordionItem.querySelector('.js-swiper').swiper;

            accordionItem.querySelector('.color-picker_wrapper').classList.add('is_ajax-loading');

            if (event.target.value != '17') {
                fetchProducts(event.target.value, page = 1, [], (products) => {
                    slider.removeAllSlides();
                    slider.appendSlide(buildSlides(products));
                    slider.slideTo(0);

                    accordionItem.querySelector('.color-picker_wrapper').classList.remove('is_ajax-loading');

                    setColor(palette == 'color-a' ? colorA : null, palette == 'color-b' ? colorB : null, palette == 'color-c' ? colorC : null, false);
                });
            } else {
                fetch('/wp-json/wc/v3/csb')
                    .then(response => response.json())
                    .then(data => {
                        let products = data;

                        slider.removeAllSlides();
                        slider.appendSlide(buildSlides(products, false));
                        slider.slideTo(0);

                        accordionItem.querySelector('.color-picker_wrapper').classList.remove('is_ajax-loading');

                        setColor(palette == 'color-a' ? colorA : null, palette == 'color-b' ? colorB : null, palette == 'color-c' ? colorC : null, false);
                    })
                    .catch((error) => {
                        console.error('Error:', error);
                    });
            }
        });
    });

    // Select / Deselect color
    window.addEventListener('click', (event) => {
        if (event.target.classList.contains('js-color-picker_select-item'))
            selectColor(event.target);
    });

    // Hide tools trigger
    document.querySelectorAll('.js-colour-scheme-builder_hide-tools').forEach(btn => {
        btn.addEventListener('click', () => {
            document.querySelector('.colour-scheme-builder_modal').classList.toggle('is-expanded');
        });
    });

    // Rotate colors
    document.querySelector('.js-colour-scheme-builder_rotate').addEventListener('click', event => {
        let palette = document.querySelector('.colour-scheme-builder_palette');

        if (palette.classList.contains('rotate-0')) {
            palette.classList.remove('rotate-0');
            palette.classList.add('rotate-1');
            setWindowLocalStorage(colorA, colorB, colorC, 1);
            setWindowHistory(colorA, colorB, colorC, 1);
        } else if (palette.classList.contains('rotate-1')) {
            palette.classList.remove('rotate-1');
            palette.classList.add('rotate-2');
            setWindowLocalStorage(colorA, colorB, colorC, 2);
            setWindowHistory(colorA, colorB, colorC, 2);
        } else {
            palette.classList.remove('rotate-2');
            palette.classList.add('rotate-0');
            setWindowLocalStorage(colorA, colorB, colorC, 0);
            setWindowHistory(colorA, colorB, colorC, 0);
        }
    });

    // Add to color scheme
    let colorToAdd = [];
    window.addEventListener('click', (event) => {
        if (event.target.classList.contains('js-add-colour-scheme')) {
            colorToAdd = [event.target.dataset.r, event.target.dataset.g, event.target.dataset.b];
            document.querySelector('.colour-scheme-builder_add-to_dropdown-wrapper').classList.add('show');
        }
    }, false);

    document.getElementById('colour-scheme-builder_add-to_dropdown_tags').addEventListener('change', (event) => {
        if (event.target.value == 'a') {
            setColor(colorToAdd, null, null);
        } else if (event.target.value == 'b') {
            setColor(null, colorToAdd, null);
        } else if (event.target.value == 'c') {
            setColor(null, null, colorToAdd);
        }
        document.querySelector('.colour-scheme-builder_add-to_dropdown-wrapper').classList.remove('show');
        event.target.value = '';
    });

    // Close modal
    window.addEventListener('click', (event) => {
        if (event.target.classList.contains('js-modal_close') || event.target.closest('.js-modal_close')) {
            window.history.pushState({}, '', window.location.origin + window.location.pathname);
        }
    }, false);
}

// Init CSB
function initCSB() {
    if (!init) {
        if (ck, cs, tagId) {
            document.querySelector('.colour-scheme-builder_wrapper').classList.add('is_ajax-loading');

            // Load products and create the sliders
            fetch('/wp-json/wc/v3/csb')
                .then(response => response.json())
                .then(data => {
                    let products = data;
                    // console.log(products);

                    if (products.length) {

                        // Build the sliders
                        document.querySelectorAll('.colour-scheme-builder_accordion-item').forEach((accordionItem, index) => {
                            let slider = accordionItem.querySelector('.js-swiper').swiper;
                            slider.appendSlide(buildSlides(products, false));

                            // Collapse accordions
                            if (index > 0) {
                                accordionItem.classList.remove('open');
                                accordionItem.querySelector('.js-accordion_trigger').classList.remove('open');
                                accordionItem.querySelector('.js-accordion_container').style.display = 'none';
                            }

                            checkModalHeight(document.getElementById('modal-colour-scheme-builder'));
                            document.querySelector('.colour-scheme-builder_wrapper').classList.remove('is_ajax-loading');
                        });

                        // Window local storage
                        if (window.localStorage.getItem('colorA') || window.localStorage.getItem('colorB') || window.localStorage.getItem('colorC')) {
                            let useLocalStorage = true;

                            if (windowLocationSearch) {
                                windowLocationSearch = window.location.search.slice(1).split('&').map(p => p.split('='));

                                windowLocationSearch.forEach(elem => {
                                    if (elem[0] == 'a' || elem[0] == 'b' || elem[0] == 'c')
                                        useLocalStorage = false;
                                });
                            }

                            if (useLocalStorage) {
                                colorA = window.localStorage.getItem('colorA') ? window.localStorage.getItem('colorA').split(',') : null;
                                colorB = window.localStorage.getItem('colorB') ? window.localStorage.getItem('colorB').split(',') : null;
                                colorC = window.localStorage.getItem('colorC') ? window.localStorage.getItem('colorC').split(',') : null;

                                window.addEventListener('click', (event) => {
                                    if (event.target.classList.contains('colour-scheme-builder_trigger'))
                                        setWindowHistory(colorA, colorB, colorC, rotation);
                                });
                            }
                        }

                        if (window.localStorage.getItem('colourSchemeBuilderPaletteRotation')) {
                            let useLocalStorage = true;

                            if (windowLocationSearch) {
                                windowLocationSearch = window.location.search.slice(1).split('&').map(p => p.split('='));

                                windowLocationSearch.forEach(elem => {
                                    if (elem[0] == 'rotation')
                                        useLocalStorage = false;
                                });
                            }

                            if (useLocalStorage) {
                                rotation = window.localStorage.getItem('colourSchemeBuilderPaletteRotation');
                                document.querySelector('.colour-scheme-builder_palette').classList.remove('rotate-0');
                                document.querySelector('.colour-scheme-builder_palette').classList.add('rotate-' + rotation);
                            }
                        }

                        // Window history
                        if (windowLocationSearch) {
                            windowLocationSearch = window.location.search.slice(1).split('&').map(p => p.split('='));
                            let colors = [];

                            windowLocationSearch.forEach(elem => {
                                if (elem[0] == 'a' || elem[0] == 'b' || elem[0] == 'c')
                                    colors.push(elem[1]);

                                if (elem[0] == 'rotation') {
                                    rotation = elem[1];
                                    document.querySelector('.colour-scheme-builder_palette').classList.remove('rotate-0');
                                    document.querySelector('.colour-scheme-builder_palette').classList.add('rotate-' + rotation);
                                }
                            });

                            colorA = colors[0] ? colors[0].split('-') : null;
                            colorB = colors[1] ? colors[1].split('-') : null;
                            colorC = colors[2] ? colors[2].split('-') : null;

                            setWindowLocalStorage(colorA, colorB, colorC, rotation);
                        }

                        // Set selected colours
                        setColor(colorA, colorB, colorC);
                    }
                })
                .catch((error) => {
                    console.error('Error:', error);
                });
        }

        init = true;
    }
}

// Fetch ALL products
function fetchProducts(tagId, page, products, callback) {
    if (page) {
        fetch('/wp-json/wc/v3/products?consumer_key=' + ck + '&consumer_secret=' + cs + '&tag=' + tagId + '&status=publish&order=asc&per_page=100&page=' + page)
            .then(response => response.json())
            .then(data => {
                if (data.length) {
                    page++;
                    products = products.concat(data);
                    fetchProducts(tagId, page++, products, callback);
                } else {
                    page = false;
                    callback(products);
                }
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    }
}

// Build slides
function buildSlides(products, wc_api = true) {
    let slides = [];

    products.forEach(product => {
        let name = '';
        let thumb = '';
        let r = '';
        let g = '';
        let b = '';
        let pot = '';
        let sheet = '';
        let filters = '';
        let exclude = false;

        if (wc_api) {
            name = product.name;
            thumb = product.images[0].thumb;
            r = product.meta_data.filter((ob) => { return (ob.key == 'red'); })[0].value;
            g = product.meta_data.filter((ob) => { return (ob.key == 'green'); })[0].value;
            b = product.meta_data.filter((ob) => { return (ob.key == 'blue'); })[0].value;
            pot = product.meta_data.filter((ob) => { return (ob.key == '_ftw_sample_pot'); })[0].value;
            sheet = product.meta_data.filter((ob) => { return (ob.key == '_ftw_painted_sheet'); })[0].value;

            let filtersObj = product.tags;
            filtersObj.forEach(filter => {
                filters = filters + filter.slug + ', ';
            });

            let excludeValue = product.meta_data.filter((ob) => { return (ob.key == 'exlude_colour_scheme_creator'); });
            if (excludeValue.length > 0 && excludeValue[0].value == 1) {
                exclude = true;
            }
        } else {
            name = product.title;
            thumb = product.thumb;
            r = product.r;
            g = product.g;
            b = product.b;
            pot = product.pot;
            sheet = product.sheet;

            product.tags.forEach(filter => {
                filters = filters + filter + ', ';
            });

            if (product.exclude == 1) {
                exclude = true;
            }
        }

        if (!exclude) {
            slides = slides.concat(`<div class="color-picker_slide swiper-slide js-swiper_slide" data-filters="` + filters.slice(0, -2) + `">
                <div class="color-picker_item js-color-picker_select-item" data-r="` + r + `" data-g="` + g + `" data-b="` + b + `" data-pot="` + pot + `" data-sheet="` + sheet + `">
                    <img src="` + thumb + `" alt="` + name + `" />
                </div>
            </div>`);
        }
    });

    return slides;
}

// Set colour
function setColor(a, b, c, updateColor = true) {
    if (a != null && a.length) {
        document.querySelectorAll('#accordion-color-a .color-picker_item').forEach(cpItem => {
            if (cpItem.dataset.r == a[0] && cpItem.dataset.g == a[1] && cpItem.dataset.b == a[2])
                selectColor(cpItem, updateColor);
        });
    }

    if (b != null && b.length) {
        document.querySelectorAll('#accordion-color-b .color-picker_item').forEach(cpItem => {
            if (cpItem.dataset.r == b[0] && cpItem.dataset.g == b[1] && cpItem.dataset.b == b[2])
                selectColor(cpItem, updateColor);
        });
    }

    if (c != null && c.length) {
        document.querySelectorAll('#accordion-color-c .color-picker_item').forEach(cpItem => {
            if (cpItem.dataset.r == c[0] && cpItem.dataset.g == c[1] && cpItem.dataset.b == c[2])
                selectColor(cpItem, updateColor);
        });
    }
}

// Select color
function selectColor(cpItem, toggle = true) {
    let accordionItem = cpItem.closest('.colour-scheme-builder_accordion-item');

    // Retreive pots/sheets IDs from buy buttons
    let dataPots = document.querySelector('.js-add-to-cart-multi-pots').dataset.products;
    let dataSheets = document.querySelector('.js-add-to-cart-multi-sheets').dataset.products;

    // Manage "is-selected" class
    let elSiblings = elemSiblings(cpItem.parentElement);
    elSiblings.forEach(sibling => sibling.querySelector('.color-picker_item').classList.remove('is-selected'));

    cpItem.classList.toggle('is-selected');

    if (toggle) {
        let palette = '.' + accordionItem.dataset.palette;
        let rgb = 'rgb(' + cpItem.dataset.r + ', ' + cpItem.dataset.g + ', ' + cpItem.dataset.b + ')';

        // Manage color info
        if (cpItem.classList.contains('is-selected')) {
            accordionItem.querySelector('.color-label').classList.remove('no-color');
            accordionItem.querySelector('.color-label').style.backgroundColor = rgb;

            accordionItem.querySelector('.color-heading').innerHTML = cpItem.querySelector('img').alt;

            document.querySelector(palette).classList.remove('no-color');
            document.querySelector(palette).style.backgroundColor = rgb;
        } else {
            accordionItem.querySelector('.color-label').classList.add('no-color');
            accordionItem.querySelector('.color-label').style.backgroundColor = '';

            accordionItem.querySelector('.color-heading').innerHTML = '';

            document.querySelector(palette).classList.add('no-color');
            document.querySelector(palette).style.backgroundColor = '';
        }

        // Set window history
        let selA = document.querySelector('#color-picker-slider-a .color-picker_item.is-selected');
        let selB = document.querySelector('#color-picker-slider-b .color-picker_item.is-selected');
        let selC = document.querySelector('#color-picker-slider-c .color-picker_item.is-selected');

        colorA = selA ? [selA.dataset.r, selA.dataset.g, selA.dataset.b] : null;
        colorB = selB ? [selB.dataset.r, selB.dataset.g, selB.dataset.b] : null;
        colorC = selC ? [selC.dataset.r, selC.dataset.g, selC.dataset.b] : null;

        // Window session
        if (window.localStorage.getItem('colourSchemeBuilderPaletteRotation'))
            rotation = window.localStorage.getItem('colourSchemeBuilderPaletteRotation');

        setWindowLocalStorage(colorA, colorB, colorC, rotation);

        // Window history
        if (document.getElementById('modal-colour-scheme-builder').classList.contains('open'))
            setWindowHistory(colorA, colorB, colorC, rotation);

        // Set color contrast
        setContrast(colorA, colorB, colorC);
    }

    // Move swiper to selected slide
    accordionItem.querySelector('.js-swiper').swiper.slideTo([...accordionItem.querySelector('.swiper-wrapper').children].indexOf(cpItem.parentElement));

    // Set buy buttons
    setBuyBtns();
}

// Set buy buttons
function setBuyBtns() {
    let potIds = [];
    let sheetIds = [];
    let btnShare = document.querySelector('.colour-scheme-builder_share');
    let btnPots = document.querySelector('.js-add-to-cart-multi-pots');
    let btnSheets = document.querySelector('.js-add-to-cart-multi-sheets');

    document.querySelectorAll('.color-picker_item').forEach(color => {
        if (color.classList.contains('is-selected')) {
            potIds.push(color.dataset.pot);
            sheetIds.push(color.dataset.sheet);
        }
    });

    btnPots.dataset.products = potIds;
    btnSheets.dataset.products = sheetIds;

    if (!btnPots.dataset.products)
        btnPots.disabled = true;
    else
        btnPots.disabled = false;

    if (!btnSheets.dataset.products)
        btnSheets.disabled = true;
    else
        btnSheets.disabled = false;

    // Share button
    if (btnPots.dataset.products || btnSheets.dataset.products) {
        btnShare.classList.remove('is-disabled');
        btnShare.querySelector('.colour-scheme-builder_share-btn').disabled = false;
    } else {
        btnShare.classList.add('is-disabled');
        btnShare.querySelector('.colour-scheme-builder_share-btn').disabled = true;
    }
}

// Set window local storage
function setWindowLocalStorage(a, b, c, rotation) {

    if (a != null)
        window.localStorage.setItem('colorA', a);
    else
        window.localStorage.setItem('colorA', '');

    if (b != null)
        window.localStorage.setItem('colorB', b);
    else
        window.localStorage.setItem('colorB', '');

    if (c != null)
        window.localStorage.setItem('colorC', c);
    else
        window.localStorage.setItem('colorC', '');

    if (rotation != null || rotation != 'undefined')
        window.localStorage.setItem('colourSchemeBuilderPaletteRotation', rotation);
    else
        window.localStorage.setItem('colourSchemeBuilderPaletteRotation', 0);
}

// Set window history
function setWindowHistory(a, b, c, rotation) {
    let windowHistory = '?';

    if (a != null)
        windowHistory = windowHistory + 'a=' + a[0] + '-' + a[1] + '-' + a[2] + '&';
    else
        windowHistory = windowHistory + 'a=null&';

    if (b != null)
        windowHistory = windowHistory + 'b=' + b[0] + '-' + b[1] + '-' + b[2] + '&';
    else
        windowHistory = windowHistory + 'b=null&';

    if (c != null)
        windowHistory = windowHistory + 'c=' + c[0] + '-' + c[1] + '-' + c[2] + '&';
    else
        windowHistory = windowHistory + 'c=null&';

    if (rotation != null || rotation != 'undefined')
        windowHistory = windowHistory + 'rotation=' + rotation;
    else
        windowHistory = windowHistory + 'rotation=null';

    if (windowHistory != '?')
        window.history.pushState({}, '', windowHistory);
    else
        window.history.pushState({}, '', window.location.origin + window.location.pathname);


    // Update share links
    document.querySelectorAll('#modal-colour-scheme-builder .js_share-link').forEach(link => {
        link.href = link.dataset.social + encodeURIComponent(window.location.href);
    });

    document.getElementById('share-modal-input').value = window.location.href;
}

// Set color contrast
function setContrast(a, b, c) {
    if (a != null && contrast([parseInt(a[0]), parseInt(a[1]), parseInt(a[2])], [79, 79, 73]) <= 4.5) {
        document.querySelector('.colour-scheme-builder_palette_color.color-a').classList.add('light');
        document.querySelector('#accordion-color-a .color-label').classList.add('light');
        document.querySelector('.colour-scheme-builder_palette .modal_btn-close').classList.add('light');
    } else {
        document.querySelector('.colour-scheme-builder_palette_color.color-a').classList.remove('light');
        document.querySelector('#accordion-color-a .color-label').classList.remove('light');
        document.querySelector('.colour-scheme-builder_palette .modal_btn-close').classList.remove('light');
    }

    if (b != null && contrast([parseInt(b[0]), parseInt(b[1]), parseInt(b[2])], [79, 79, 73]) <= 4.5) {
        document.querySelector('.colour-scheme-builder_palette_color.color-b').classList.add('light');
        document.querySelector('#accordion-color-b .color-label').classList.add('light');
    } else {
        document.querySelector('.colour-scheme-builder_palette_color.color-b').classList.remove('light');
        document.querySelector('#accordion-color-b .color-label').classList.remove('light');
    }

    if (c != null && contrast([parseInt(c[0]), parseInt(c[1]), parseInt(c[2])], [79, 79, 73]) <= 4.5) {
        document.querySelector('.colour-scheme-builder_palette_color.color-c').classList.add('light');
        document.querySelector('#accordion-color-c .color-label').classList.add('light');
    } else {
        document.querySelector('.colour-scheme-builder_palette_color.color-c').classList.remove('light');
        document.querySelector('#accordion-color-c .color-label').classList.remove('light');
    }
}
