import { serializeForm } from './support';
import { createAlertMsg } from './flash-message';

// Update Cart
window.addEventListener('click', (event) => {
    if (event.target.classList.contains('js-update-cart')) {
        event.preventDefault();
        event.stopPropagation();

        let btn = event.target;
        let alertMessages = JSON.parse(btn.dataset.alerts);

        btn.disabled = true;
        btn.classList.add('is_ajax-loading');

        fetch(btn.dataset.url)
            .then((response) => {

                // Alert messages
                let alertType = 'valid';
                let alertMessage = alertMessages.valid;

                if (response.status !== 200) {
                    alertType = 'invalid';
                    alertMessage = alertMessages.invalid;
                }

                // Update mini cart
                updateMiniCart(btn, alertType, alertMessage);
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    }
}, false);

// Listen for WooCommerce "updated_cart_totals" event and update mini cart
window.onload = () => {
    if(window.jQuery) {
        jQuery(document.body).on('updated_cart_totals', () => {
            updateMiniCart();
        });

        // Update Cart on Successful Order
        jQuery(document).ready(() => {
            if (window.location.href.indexOf("/checkout/order-received/") > -1) {
                updateMiniCart();
            }
        });
    }
}


// Update mini cart
function updateMiniCart(btn = null, alertType = null, alertMessage = null) {

    fetch('/ajax-cart/')
        .then(response => response.json())
        .then(data => {
            let headerCartListDesktop = document.getElementById('mini-cart-desktop');
            let headerCartListMobile = document.getElementById('mini-cart-mobile');

            // Empty cart list
            if (data.contents.length < 1) {
                headerCartListDesktop.innerHTML = '<p class="header-cart_list-empty">Your basket is currently empty.</p>';
                headerCartListMobile.innerHTML = '<p class="header-cart_list-empty">Your basket is currently empty.</p>';
            } else {
                headerCartListDesktop.innerHTML = '';
                headerCartListMobile.innerHTML = '';
            }

            // Add refreshed cart items
            data.contents.forEach(item => {
                let headerCartItem = document.querySelector('.js-header-cart_item-template').cloneNode(true);

                headerCartItem.classList.remove('hide-desktop', 'hide-mobile', 'js-header-cart_item-template');
                headerCartItem.querySelector('.header-cart_item_title a').href = item.product_link;
                headerCartItem.querySelector('.header-cart_item_title a').innerHTML = item.product_title;
                headerCartItem.querySelector('.header-cart_item_finish span').innerHTML = item.variation['attribute_pa_select-paint'];
                headerCartItem.querySelector('.header-cart_item_size span').innerHTML = item.variation['attribute_pa_select-size'];
                headerCartItem.querySelector('.header-cart_item_quantity').innerHTML = item.quantity + ' x &pound;' + ((item.line_total + item.line_tax) / item.quantity).toFixed(2);
                headerCartItem.querySelector('.header-cart_item_thumb').href = item.product_link;
                headerCartItem.querySelector('.header-cart_item_thumb').innerHTML = '<img src="' + item.product_thumbnail + '" alt="' + item.product_title + '">';
                headerCartItem.querySelector('.header-cart_item_remove').dataset.url = item.remove_url;
                headerCartItem.querySelector('.header-cart_item_remove').dataset.alerts = headerCartItem.querySelector('.header-cart_item_remove').dataset.alerts.replace(/:product_name/g, item.product_title);

                headerCartListDesktop.appendChild(headerCartItem);
                headerCartListMobile.innerHTML = headerCartListDesktop.innerHTML;
            });

            // Update header cart count
            document.querySelectorAll('.js-header-cart_count').forEach((count) => count.innerHTML = data.count);

            // Update header cart total
            document.querySelectorAll('.js-header-cart_total > span').forEach((total) => total.innerHTML = data.total);

            // Show alert message
            if (alertType && alertMessage)
                createAlertMsg(alertType, alertMessage);

            // Remove loader from button
            if (btn) {
                btn.disabled = false;
                btn.classList.remove('is_ajax-loading');
            }
        })
        .catch((error) => {
            console.error('Error:', error);
        });
}

// Add to cart
if (document.querySelectorAll('form.cart').length > 0) {
    document.querySelectorAll('form.cart').forEach(form => addToCart(form));
}

function addToCart(form) {
    let btn = form.querySelector('button[type="submit"]');
    let alertMessages = JSON.parse(document.querySelector('.woocommerce-main_content').dataset.alerts);
    let isSimple = (form.closest('.is-simple') ? true : false);
    let productID = document.querySelector('.woocommerce-product_rhc').dataset.productid;

    btn.classList.add('has_ajax-loader');

    form.addEventListener('submit', event => {
        event.preventDefault();
        event.stopPropagation();

        btn.disabled = true;
        btn.classList.add('is_ajax-loading');

        let request = new XMLHttpRequest();
        let postURL = (isSimple ? '?add-to-cart=' + productID : event.target.getAttribute('action'));

        request.open('POST', postURL, true);
        request.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8');
        request.setRequestHeader('X-Requested-With', 'XMLHttpRequest');

        request.onload = function () {
            if (request.status >= 200 && request.status < 400) {
                updateMiniCart(btn, 'valid', alertMessages.valid);
            } else {
                updateMiniCart(btn, 'invalid', alertMessages.invalid);
            }
        }

        request.onerror = function () {
            updateMiniCart(btn, 'invalid', alertMessages.invalid);
        };

        request.send(serializeForm(event.target));
    });
}

// Add to cart multi pots / sheets
let multiPots = document.querySelectorAll('.js-add-to-cart-multi-pots');

if (multiPots) {
    [...multiPots].forEach(btn => btn.addEventListener('click', event => addToCartMulti(event.target)));
}

let multiSheets = document.querySelectorAll('.js-add-to-cart-multi-sheets');

if (multiSheets) {
    [...multiSheets].forEach(btn => btn.addEventListener('click', event => addToCartMulti(event.target)));
}

function addToCartMulti(btn) {
    let alertMessages = JSON.parse(btn.dataset.alerts);

    btn.disabled = true;
    btn.classList.add('is_ajax-loading');

    let request = new XMLHttpRequest();

    request.open('POST', '/cart/?add-to-cart=' + btn.dataset.products, true);
    request.setRequestHeader('X-Requested-With', 'XMLHttpRequest');

    request.onload = function () {
        if (request.status >= 200 && request.status < 400) {
            updateMiniCart(btn, 'valid', alertMessages.valid);
        } else {
            updateMiniCart(btn, 'invalid', alertMessages.invalid);
        }
    }

    request.onerror = function () {
        updateMiniCart(btn, 'invalid', alertMessages.invalid);
    };

    request.send();
}
