/*
 * Essential Imports
 */

import 'picturefill';
import 'lazysizes';
import './vendor/ls.object-fit';
import Player from '@vimeo/player';

// video player
const vimeoIframes = document.querySelectorAll('.vimeo-iframe');
if (vimeoIframes.length) {
    vimeoIframes.forEach((iframe) => {
        const soundBtnEle = iframe.nextElementSibling;
        const pauseBtnEle = iframe.nextElementSibling.nextElementSibling;
        const mutedClass = 'is-muted';
        const playingClass = 'is-playing';

        const autoplay = iframe.getAttribute('data-autoplay') === 'true';
        const mute = iframe.getAttribute('data-mute') === 'true';
        const mode = iframe.getAttribute('data-mode');

        const player = new Player(iframe);
        player.on('loaded', () => {
            if (autoplay) {
                if (window.innerWidth > 768 && mode === 'desktop') {
                    player.play();
                    if (pauseBtnEle) pauseBtnEle.classList.add(playingClass);
                } else if (window.innerWidth <= 768 && mode === 'mobile') {
                    player.play();
                    if (pauseBtnEle) pauseBtnEle.classList.add(playingClass);
                }
            }
            if (mute) {
                player.setVolume(0);
                if (
                    soundBtnEle &&
                    soundBtnEle.classList.contains('vimeo-iframe__sound')
                ) {
                    soundBtnEle.classList.add(mutedClass);
                }
            }
        });

        if (soundBtnEle) {
            soundBtnEle.addEventListener('click', () => {
                if (soundBtnEle.classList.contains(mutedClass)) {
                    player.setVolume(1);
                    soundBtnEle.classList.remove(mutedClass);
                } else {
                    player.setVolume(0);
                    soundBtnEle.classList.add(mutedClass);
                }
            });
        }
        if (pauseBtnEle) {
            pauseBtnEle.addEventListener('click', () => {
                if (pauseBtnEle.classList.contains(playingClass)) {
                    player.pause();
                    pauseBtnEle.classList.remove(playingClass);
                } else {
                    player.play();
                    pauseBtnEle.classList.add(playingClass);
                }
            });
        }
    });
}

/*
 * Essentail Fixes
 */

// Preload and No-JS
setTimeout(function () {
    document.documentElement.classList.remove('preload');
    document.documentElement.classList.remove('no-js');
}, 300);

let resizeId;
window.addEventListener('resize', function () {
    clearTimeout(resizeId);
    document.documentElement.classList.add('preload');
    resizeId = setTimeout(function () {
        document.documentElement.classList.remove('preload');
    }, 300);
});

// Fix NodeList.forEach in IE11
if (window.NodeList && !NodeList.prototype.forEach) {
    NodeList.prototype.forEach = Array.prototype.forEach;
}

/*
 * Accordion
 */

import Accordion from './functions/accordion';
const accordion = new Accordion();

/*
 * AppendAround
 */

import appendAround from './functions/append-around';

window.addEventListener('load', function () {
    appendAround();
});

/*
 * Cart
 */

import './functions/cart';

window.addEventListener('click', (event) => {
    if (event.target.classList.contains('js-toggle-wc-buy-now-form')) {
        const wcBuyNow = event.target.closest('.wc--buy-now');

        if (wcBuyNow) {
            const wcBuyNowForm = wcBuyNow.querySelector('.wc--buy-now--form');

            if (wcBuyNowForm) {
                wcBuyNowForm.classList.toggle('is-active');
            }
        }
    }
});

window.addEventListener('change', (event) => {
    if (event.target.id === 'pa_select-paint') {
        const accBlock = document.querySelector(
            `[data-slug="${event.target.value}"`
        );

        if (accBlock) {
            const accContainer = accBlock.querySelector(
                '.js-accordion_container'
            );

            if (!accContainer.classList.contains('is-animating')) {
                if (accBlock.classList.contains('open')) {
                    accBlock.classList.remove('open');
                    slideUp(accContainer);
                } else {
                    accBlock.classList.add('open');
                    slideDown(accContainer);
                }

                // Close siblings
                let elSiblings = elemSiblings(accBlock);
                elSiblings.forEach((sibling) => {
                    sibling.classList.remove('open');
                    sibling
                        .querySelector('.js-accordion_trigger')
                        .classList.remove('open');
                    slideUp(sibling.querySelector('.js-accordion_container'));
                });

                // Check for open modals
                if (accBlock.closest('.js-modal_wrapper.open')) {
                    setTimeout(() => {
                        let modal = accBlock.closest('.js-modal_wrapper.open');
                        let heightBody = window.innerHeight;
                        let heightModalContent =
                            modal.querySelector(
                                '.js-modal_content'
                            ).offsetHeight;

                        if (heightModalContent >= heightBody)
                            modal.classList.add('fixedHeight');
                        else modal.classList.remove('fixedHeight');
                    }, 300);
                }
            }
        }
    }
});

import Cookies from 'js-cookie';
let cookieColorSchemeTrigger = document.querySelector(
    '.colour-scheme-builder_trigger'
);

if (cookieColorSchemeTrigger) {
    if (Cookies.get('cookieColorSchemeTrigger') === 'true') {
        setTimeout(() => {
            cookieColorSchemeTrigger.classList.add('is-cookie-set');
        }, 600);
    } else {
        Cookies.set('cookieColorSchemeTrigger', 'true');
    }
}

/*
 * Colour Card Tooltip
 */

import './functions/colour-card-tooltip';

/*
 * Colour Scheme Builder
 */

import './functions/colour-scheme-builder';

/*
 * Copy Share Button
 */

if (
    typeof document.getElementById('share-modal-button') != 'undefined' &&
    document.getElementById('share-modal-button') != null
) {
    document
        .getElementById('share-modal-button')
        .addEventListener('click', (button) => {
            var copyText = document.getElementById('share-modal-input');

            copyText.select();
            copyText.setSelectionRange(0, 99999);

            document.execCommand('copy');

            // alert('Text copied: ' + copyText.value);
            createAlertMsg('valid', 'Sharing URL copied to clipboard.');
        });
}

/*
 * Coverage Calculator
 */

import './functions/coverage-calculator';

/*
 * Finish Chooser Tool
 */

if (
    typeof document.querySelector('table.variations') != 'undefined' &&
    document.querySelector('table.variations') != null
) {
    let btn1 = document.querySelector('.finish-chooser_trigger');
    let btn2 = document.querySelector('.coverage-calculator_trigger');

    if (
        typeof document.querySelector('#pa_select-paint') != 'undefined' &&
        document.querySelector('#pa_select-paint') != null
    ) {
        document
            .querySelector('#pa_select-paint')
            .parentElement.parentElement.after(btn1);
    } else {
        btn1.style.display = 'none';
    }

    if (
        typeof document.querySelector('#pa_select-size') != 'undefined' &&
        document.querySelector('#pa_select-size') != null
    ) {
        document
            .querySelector('#pa_select-size')
            .parentElement.parentElement.after(btn2);
    } else {
        btn2.style.display = 'none';
    }
}

if (
    typeof document.getElementById('finish_chooser_surfaces') != 'undefined' &&
    document.getElementById('finish_chooser_surfaces') != null
) {
    const finish_chooser = document.getElementById('finish_chooser_surfaces');
    const finish_chooser_finishes = document.getElementById(
        'finish_chooser_finishes'
    );
    const surface_data = JSON.parse(
        document.querySelector('.finish_chooser').dataset.surfaces
    );

    finish_chooser.addEventListener('change', () => {
        hideAllFinishes();

        let surface = surface_data.find(
            ({ surface_slug }) => surface_slug === finish_chooser.value
        );

        // Clear options from dropdown
        for (i = finish_chooser_finishes.options.length - 1; i >= 1; i--) {
            finish_chooser_finishes.options[i] = null;
        }

        if (finish_chooser.value != '') {
            // Add new options to dropdown
            for (
                let index = 0;
                index < surface.preferred_finishes.length;
                index++
            ) {
                let finish = surface.preferred_finishes[index];
                let opt = document.createElement('option');
                opt.value = finish.value;
                opt.innerHTML = finish.label;
                finish_chooser_finishes.appendChild(opt);
            }
        }

        // Check modal height
        checkModalHeight(document.getElementById('modal-finish-chooser'));
    });

    if (
        typeof finish_chooser_finishes != 'undefined' &&
        finish_chooser_finishes != null
    ) {
        finish_chooser_finishes.addEventListener('change', () => {
            hideAllFinishes();
            showFinishText();

            // Check modal height
            checkModalHeight(document.getElementById('modal-finish-chooser'));
        });
    }

    function hideAllFinishes() {
        document.querySelectorAll('.finish-result').forEach((x) => {
            x.classList.remove('show');

            // x.style.opacity = '0';
            // x.style.visibility = 'hidden';
        });
    }

    function showFinishText() {
        if (finish_chooser_finishes.value != '') {
            let finish_result = document.querySelector(
                '.' + finish_chooser_finishes.value
            );
            if (typeof finish_result != 'undefined' && finish_result != null) {
                finish_result.classList.add('show');

                // finish_result.style.opacity = '1';
                // finish_result.style.visibility = 'visible';
            }

            let select_finish_btn = finish_result.querySelector(
                '.select-finish-button'
            );
            if (
                typeof select_finish_btn != 'undefined' &&
                select_finish_btn != null
            ) {
                select_finish_btn.addEventListener('click', () => {
                    let finish = select_finish_btn.dataset.finish;
                    document.getElementById('pa_select-paint').value = finish;
                    jQuery('#pa_select-paint').trigger('change');
                });
            }
        }
    }
}

/*
 * FlashMessage
 */

import {
    default as FlashMessage,
    createAlertMsg,
} from './functions/flash-message';
const flash_message = new FlashMessage();

/*
 * Forms
 */

// FormValidation
import './functions/form-validation';
document.querySelectorAll('.form.js-validate').forEach((form) => {
    if (form.querySelector('.g-recaptcha')) {
        recaptcha = document
            .querySelector("meta[name='recaptcha_site_key']")
            .getAttribute('content');
    } else {
        recaptcha = false;
    }

    new Validate(form, {
        recaptchaSiteKey: recaptcha,
        fieldFeedbackSelector: '.js-field-feedback span',
    });

    // Focus invalid field behaviour
    form.addEventListener('submit', function () {
        let invalidField = form.querySelector('.js-field.is-invalid');
        if (invalidField) {
            scrollToElem(invalidField);
        }
    });
});

// In-field Labels
document.querySelectorAll('.js-infield').forEach((field) => {
    field.addEventListener('keyup', (event) => {
        field.setAttribute('value', field.value);
    });
});

/*
 * Free Colour Card Modal
 */

// Modal
if (
    typeof document.getElementById('colour_card_country') != 'undefined' &&
    document.getElementById('colour_card_country') != null
) {
    document
        .getElementById('colour_card_country')
        .addEventListener('change', function () {
            if (this.value == 'GB') {
                document
                    .querySelector('.colour_card_postcode_container')
                    .classList.remove('hide-all');
                document.querySelector(
                    "label[for='colour_card_postcode']"
                ).innerHTML = 'Postcode';
                if (
                    document.getElementById('colour_card_postcode').value == ''
                ) {
                    document
                        .querySelector('.extra-address-fields')
                        .classList.add('hide-all');
                }
            } else if (this.value != '') {
                document.querySelector(
                    "label[for='colour_card_postcode']"
                ).innerHTML = 'ZIP/Postcode';
                document
                    .querySelector('.colour_card_postcode_container')
                    .classList.remove('hide-all');
                document
                    .querySelector('.extra-address-fields')
                    .classList.remove('hide-all');
            }

            // Check modal height
            checkModalHeight(document.getElementById('modal-colour-card'));
        });
}

// Fetchify
let fetchify_token = document
    .querySelector("meta[name='fetchify_token']")
    ?.getAttribute('content');
new clickToAddress({
    accessToken: fetchify_token,
    dom: {
        search: 'colour_card_postcode',
        line_1: 'colour_card_address',
        line_2: 'colour_card_address2',
        town: 'colour_card_city',
        postcode: 'colour_card_postcode',
        county: 'colour_card_county',
        country: 'colour_card_country',
    },
    domMode: 'id',
    placeholders: false,
    gfxMode: 1,
    style: {
        ambient: 'light',
        accent: 'default',
    },
    onResultSelected: function () {
        document
            .querySelector('.extra-address-fields')
            .classList.remove('hide-all');

        // Check modal height
        checkModalHeight(document.getElementById('modal-colour-card'));
    },
});

/*
 * Gallery (WooCommerce)
 */

import './functions/wc-gallery';

/*
 * GoTo Elem
 */

if (
    typeof document.querySelector('.js-goto_elem') != 'undefined' &&
    document.querySelector('.js-goto_elem') != null
) {
    document.querySelectorAll('.js-goto_elem').forEach((el) => {
        let elem = document.getElementById(el.dataset.elem);

        if (elem) {
            el.addEventListener('click', (event) => {
                scrollToElem(elem);
            });
        }
    });
}

// On Page Load
window.onload = () => {
    if (window.location.hash) {
        let elem = document.getElementById(window.location.hash.substring(1));

        if (elem) scrollToElem(elem, 24);
    }
};

/*
 * Modal Window
 */

import {
    default as ModalWindow,
    toggleModalWindow,
    checkModalHeight,
} from './functions/modal-window';
const modal_window = new ModalWindow();

/*
 * Navigation (Primary / Top - Mobile)
 */

if (
    typeof document.querySelector('.nav-mobile .nav-sublist_trigger') !=
        'undefined' &&
    document.querySelector('.nav-mobile .nav-sublist_trigger') != null
) {
    document
        .querySelectorAll('.nav-mobile .nav-sublist_trigger')
        .forEach((el) => {
            el.addEventListener('click', (event) => {
                event.currentTarget.nextElementSibling.classList.toggle('open');
            });
        });
}

/*
 * Post Category Dropdown Filter
 */

if (
    typeof document.getElementById('post_categories_category_menu') !=
        'undefined' &&
    document.getElementById('post_categories_category_menu') != null
) {
    document
        .getElementById('post_categories_category_menu')
        .addEventListener('change', function () {
            if (this.value != '' && window.location != this.value) {
                window.location = this.value;
            }
        });
}

/*
 * Product Tag Dropdown Filter
 */

if (
    typeof document.getElementById('product_filter_tags') != 'undefined' &&
    document.getElementById('product_filter_tags') != null
) {
    document
        .getElementById('product_filter_tags')
        .addEventListener('change', function () {
            if (this.value != '' && window.location != this.value) {
                window.location = this.value;
            }
        });
}

/*
 * Colour Collection Dropdown Filter
 */

if (
    typeof document.getElementById('colour_collection_filter_tags') !=
        'undefined' &&
    document.getElementById('colour_collection_filter_tags') != null
) {
    const urlSearchParams = new URLSearchParams(window.location.search);
    if (urlSearchParams) {
        const params = Object.fromEntries(urlSearchParams.entries());
        let selectedColour = params.colour;
        if (selectedColour) {
            window.addEventListener('DOMContentLoaded', () => {
                document.getElementById('colour_collection_filter_tags').value =
                    selectedColour;
                filterColours(selectedColour);
            });
        }
    }

    document
        .getElementById('colour_collection_filter_tags')
        .addEventListener('change', function () {
            filterColours(this.value);
        });

    function filterColours(colour) {
        let filterValue = colour;
        let windowHistory = '?';

        if (filterValue != '') {
            document.querySelectorAll('.colour-card').forEach((x) => {
                let filters = JSON.parse(x.dataset.filter);
                x.parentElement.classList.add('hide-all');
                if (filters != null && filters.includes(filterValue)) {
                    x.parentElement.classList.remove('hide-all');
                }
            });
            windowHistory = windowHistory + 'colour=' + filterValue;
        } else {
            document.querySelectorAll('.colour-card').forEach((x) => {
                x.parentElement.classList.remove('hide-all');
            });
        }

        if (windowHistory != '?')
            window.history.pushState({}, '', windowHistory);
        else
            window.history.pushState(
                {},
                '',
                window.location.origin + window.location.pathname
            );
    }
}

/*
 * Search
 */

document
    .querySelector('.js-header-search_trigger')
    .addEventListener('click', () => {
        document.querySelector('.js-header-search-input').focus();
    });

document
    .querySelector('.js-header-search-input')
    .addEventListener('focus', () => {
        document.querySelector('.js-header-search').classList.add('is-open');
    });
document
    .querySelector('.js-header-search-input')
    .addEventListener('blur', () => {
        document.querySelector('.js-header-search').classList.remove('is-open');
    });

/*
 * StickyHeader
 */

import StickyHeader from './functions/sticky-header';
const sticky_header = new StickyHeader();

/*
 * Swiper
 */

import Swiper from './vendor/swiper';
document.querySelectorAll('.js-swiper').forEach((swiper) => {
    let params = swiper.dataset.swiper;
    let mySwiper = new Swiper(swiper, JSON.parse(params));

    // Swiper Height
    document.addEventListener('lazyloaded', () => {
        if (swiper.dataset.autoheight) mySwiper.updateAutoHeight();
    });
});

// Swiper GOTO
document.querySelectorAll('.js-swiper_goto').forEach((goto) => {
    goto.addEventListener('click', (event) => {
        event.currentTarget.blur();

        let swiperSlider = JSON.parse(goto.dataset.slider);
        let swiperSlide = goto.dataset.slide;

        for (var i = 0; i < swiperSlider.length; i++) {
            let mySwiper = document.getElementById(swiperSlider[i]).swiper;
            mySwiper.slideTo(swiperSlide);
        }
    });
});

/*
 * Tabbing
 */

const handleFirstTab = (e) => {
    if (e.keyCode === 9) {
        document.body.classList.add('has-tabbing-user');

        window.removeEventListener('keydown', handleFirstTab);
        window.addEventListener('mousedown', handleMouseDownOnce);
    }
};

const handleMouseDownOnce = () => {
    document.body.classList.remove('has-tabbing-user');

    window.removeEventListener('mousedown', handleMouseDownOnce);
    window.addEventListener('keydown', handleFirstTab);
};

window.addEventListener('keydown', handleFirstTab);

/*
 * Video
 */

import Video from './functions/video';
const video = new Video();

/*
 * Viewport
 */

// Define our viewportWidth variable
let viewportWidth;

// Set/update the viewportWidth value
let setViewportWidth = function () {
    viewportWidth = window.innerWidth || document.documentElement.clientWidth;
};

// Log the viewport width into the console
let logWidth = function () {
    if (
        typeof document.querySelector('.js-viewport_swap') != 'undefined' &&
        document.querySelector('.js-viewport_swap') != null
    ) {
        document.querySelectorAll('.js-viewport_swap').forEach((el) => {
            let originalElement = el;
            let originalElementClone = el.cloneNode(true);
            let swapElement = document.getElementById(el.dataset.swap);
            let swapElementClone = swapElement.cloneNode(true);

            if (viewportWidth > 768) {
                if (originalElementClone.classList.contains('is-swapped')) {
                    swapElement.parentNode.replaceChild(
                        originalElementClone,
                        swapElement
                    );
                    originalElement.parentNode.replaceChild(
                        swapElementClone,
                        originalElement
                    );

                    originalElementClone.classList.remove('is-swapped');
                }
            } else {
                if (!originalElementClone.classList.contains('is-swapped')) {
                    swapElement.parentNode.replaceChild(
                        originalElementClone,
                        swapElement
                    );
                    originalElement.parentNode.replaceChild(
                        swapElementClone,
                        originalElement
                    );

                    originalElementClone.classList.add('is-swapped');
                }
            }
        });
    }
};

// Set our initial width and log it
setViewportWidth();
logWidth();

// On resize events, recalculate and log
window.addEventListener(
    'resize',
    function () {
        setViewportWidth();
        logWidth();
    },
    false
);

/*
 * Support Functions
 */

import {
    elemSiblings,
    scrollToElem,
    slideUp,
    slideDown,
} from './functions/support';

/*
 * Cookie Controller
 */

// import "./functions/cookie-controller";
// const metaCC = JSON.parse(document.querySelector('meta[name="cc"]').content);

// const cookieController = new CookieController({
//     layout: "banner",
//     requiredCookies: ["PHPSESSID"],
//     websiteName: metaCC.websiteName,
//     cookiePolicyLink: metaCC.cookiePolicyLink,
//     bannerContent: metaCC.bannerContent,
//     tooltipContent: "",
//     optionalGroups: [
//         {
//             name: "analytics",
//             label: "Analytics",
//             description:
//                 "These cookies allow us to <strong>anonymously</strong> collect data about how you browse and use our website to improve it for you and others over time.",
//             cookies: [
//                 "_ga",
//                 "_ga_QDEZCD4BZB",
//                 "1P_JAR",
//                 "_hjAbsoluteSessionInProgress",
//                 "_hjIncludedInPageviewSample",
//                 "_hjIncludedInSessionSample",
//                 "_hjid",
//                 "_hjIncludedInPageviewSample",
//                 "_hjSession_2537414",
//                 "_hjSessionUser_2537414",
//             ],
//             defaultState: "off",
//             recommendedState: "on",
//             onAllow() {
//                 dataLayer.push({
//                     event: "ccAnalytics",
//                     allow: "allow",
//                     eventCallback: function () {
//                         gritEvent.init();
//                     },
//                 });
//             },
//             onDisallow() {
//                 dataLayer.push({
//                     event: "ccAnalytics",
//                     allow: "",
//                 });
//             },
//         },
//         {
//             name: "social",
//             label: "Social",
//             description:
//                 "These cookies allow us to <strong>anonymously</strong> collect data about how you browse and use our website to improve it for you and others over time.",
//             cookies: ["fr", "_fbp"],
//             defaultState: "off",
//             recommendedState: "on",
//             onAllow() {
//                 dataLayer.push({
//                     event: "ccSocial",
//                     allow: "allow",
//                 });
//             },
//             onDisallow() {
//                 dataLayer.push({
//                     event: "ccSocial",
//                     allow: "",
//                 });
//             },
//         },
//         {
//             name: "marketing",
//             label: "Marketing",
//             description:
//                 "These cookies allow us to <strong>anonymously</strong> collect data about how you browse and use our website to improve it for you and others over time.",
//             cookies: [
//                 "wp_automatewoo_session_started",
//                 "wp_automatewoo_visitor_*",
//             ],
//             defaultState: "off",
//             recommendedState: "on",
//             onAllow() {
//                 dataLayer.push({
//                     event: "ccMarketing",
//                     allow: "allow",
//                 });
//             },
//             onDisallow() {
//                 dataLayer.push({
//                     event: "ccMarketing",
//                     allow: "",
//                 });
//             },
//         },
//     ],
// });

/*
 * GA Events
 */

import './functions/ga-events';

/*
 * Inspiration gallery grid
 */

if (
    typeof document.querySelector('.igg-load-more') != 'undefined' &&
    document.querySelector('.igg-load-more') != null
) {
    document.querySelectorAll('.igg-load-more').forEach((el) => {
        el.addEventListener('click', (event) => {
            let igg_grid = event.target.parentNode.querySelector(
                '.inspiration-gallery-grid'
            );

            let igg_hidden_items = igg_grid.querySelectorAll(
                '.inspiration-gallery-grid-item.hide-all'
            );

            for (let index = 0; index < igg_hidden_items.length; index++) {
                if (index < 9) {
                    igg_hidden_items[index].classList.remove('hide-all');
                }
            }

            if (
                igg_grid.querySelectorAll(
                    '.inspiration-gallery-grid-item.hide-all'
                ).length === 0
            ) {
                event.target.classList.add('hide-all');
            }
        });
    });
}
