
if(typeof(document.querySelector('.woocommerce-product-gallery')) != 'undefined' && document.querySelector('.woocommerce-product-gallery') != null) {
    document.querySelectorAll('.woocommerce-product-gallery').forEach((gallery, index) => {
        const wcGalleryTemplate = document.getElementById('modal-gallery');
        const wcGallerySlider = wcGalleryTemplate.querySelector('.gallery-modal');
        const wcGallerySliderWrapper = wcGalleryTemplate.querySelector('.swiper-wrapper');
        const wcGalleryBtnClose = wcGalleryTemplate.querySelector('.gallery-modal_btn-close');
        const wcGalleryBtnPrev = wcGalleryTemplate.querySelector('.gallery-modal_btn-prev');
        const wcGalleryBtnNext = wcGalleryTemplate.querySelector('.gallery-modal_btn-next');
        const wcGalleryImages = gallery.querySelectorAll('.woocommerce-product-gallery__image a');

        // Set unique attributes
        wcGalleryTemplate.id = wcGalleryTemplate.id + '-' + index;
        wcGallerySlider.id = wcGallerySlider.id + '-' + index;
        wcGallerySlider.dataset.swiper = '{ "slidesPerView" : 1, "navigation" : { "prevEl" : "#modal-gallery-prev-' + index + '", "nextEl" : "#modal-gallery-next-' + index + '" }, "keyboard" : { "enabled" : true, "onlyInViewport" : false } }';
        wcGalleryBtnClose.dataset.modal =wcGalleryBtnClose.dataset.modal + '-' + index;
        wcGalleryBtnPrev.id = wcGalleryBtnPrev.id + '-' + index;
        wcGalleryBtnNext.id = wcGalleryBtnNext.id + '-' + index;

        // console.log(wcGalleryTemplate);

        if (wcGalleryImages) {
            wcGalleryImages.forEach((anchor, index) => {

                // Add modal trigger to anchor
                anchor.classList.add('gallery-modal_trigger', 'js-modal_trigger', 'js-swiper_goto');
                anchor.dataset.modal = wcGalleryTemplate.id;
                anchor.dataset.slider = '["' + wcGallerySlider.id + '"]';
                anchor.dataset.slide = index;

                anchor.addEventListener('click', event => {
                    event.preventDefault();
                });

                let plus = document.createElement('span');
                plus.classList.add('gallery-modal_plus');
                plus.innerHTML = '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48"><path d="M41.7,6.3c-4-4-9.4-6.3-15.1-6.3l0,0C14.8,0,5.2,9.6,5.2,21.4c0,4.8,1.6,9.2,4.2,12.7L0,43.6L4.4,48l9.4-9.4c3.6,2.6,8,4.2,12.7,4.2c11.8,0,21.4-9.6,21.4-21.4C48,15.7,45.8,10.3,41.7,6.3z M41.7,21.4c0,8.3-6.8,15.1-15.1,15.1s-15.1-6.8-15.1-15.1S18.3,6.3,26.6,6.3l0,0c4,0,7.8,1.6,10.7,4.4C40.1,13.6,41.7,17.4,41.7,21.4L41.7,21.4z"/><polygon points="27.9,19.2 27.9,11.4 25.3,11.4 25.3,19.2 25.3,20.1 24.4,20.1 16.6,20.1 16.6,22.7 24.4,22.7 25.3,22.7 25.3,23.6 25.3,31.4 27.9,31.4 27.9,23.6 27.9,22.7 28.8,22.7 36.6,22.7 36.6,20.1 28.8,20.1 27.9,20.1 "/></svg>';

                anchor.appendChild(plus);

                // Create swiper slide element
                let slide = document.createElement('div');
                slide.classList.add('swiper-slide', 'js-swiper_slide');

                let item = document.createElement('div');
                item.classList.add('gallery-modal_item');

                let picture = document.createElement('picture');
                picture.classList.add('lazyload-wrapper', 'gallery-modal_image-wrapper');

                let image = document.createElement('img');
                image.classList.add('lazyload', 'gallery-modal_image');
                image.src = anchor.href;

                picture.appendChild(image);
                item.appendChild(picture);
                slide.appendChild(item);

                wcGallerySliderWrapper.appendChild(slide);
            });
        }
    });
}
