if(typeof(document.querySelector('.js-colour-card_tooltip-trigger')) != 'undefined' && document.querySelector('.js-colour-card_tooltip-trigger') != null) {
    document.querySelectorAll('.js-colour-card_tooltip-trigger').forEach(btn => {
        btn.addEventListener('click', () => {
            let card = btn.closest('.colour-card');
            let tooltip = document.getElementById(btn.dataset.tooltip);

            card.classList.add('has-open-tooltip');

            checkOverflow(tooltip);

            if (card.classList.contains('has-open-tooltip')) {
                window.addEventListener('resize', () => {
                    checkOverflow(tooltip);
                });

                window.addEventListener('click', closeTooltip, true);

                function closeTooltip(event) {
                    if (!card.contains(event.target) || event.target.classList.contains('colour-card_tooltip-close')) {
                        card.classList.add('is-closing-tooltip');
                        card.classList.remove('has-open-tooltip');

                        setTimeout(() => {
                            card.classList.remove('is-closing-tooltip');
                            tooltip.classList.remove('overflows-left', 'overflows-right');
                        }, 300);

                        window.removeEventListener('click', closeTooltip, true);
                    }
                }
            }
        });
    });

    function checkOverflow(tooltip) {
        tooltip.classList.remove('overflows-left', 'overflows-right');

        let bounding = tooltip.querySelector('.colour-card_tooltip-content').getBoundingClientRect();

        if (bounding.right > (window.innerWidth || document.documentElement.clientWidth)) {
            tooltip.classList.add('overflows-right');

            let bounding = tooltip.getBoundingClientRect();

            if (bounding.left < 0) {
                tooltip.classList.add('overflows-left');
            }
        }
    }
}
